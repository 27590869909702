.chat {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  --chat-message-shift: 15%;
}

.chat-dialog-wrapper {
  box-sizing: border-box;
  height: calc(100% - var(--footer-height));
  display: flex;
  justify-content: center;
  padding: 0 6.4rem;
  background-color: var(--chat-background-color);
}

.chat-dialog {
  max-width: 70rem;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: end;
  max-height: 100%;
}

.footer {
  height: var(--footer-height);
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: 0 6.4rem;
  border-top: var(--box-border);
}

.user-input-wrapper {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  max-width: 70rem;
  background-color: #fff;
  /* makes proper shadow display on ios */
  position: relative;
}

.message-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 2rem 0;
}

.interviewer-message-wrapper {
  margin-right: var(--chat-message-shift);
}

.respondent-message-wrapper {
  margin-left: var(--chat-message-shift);
}

.message {
  flex: 1;
}

.message-wrapper .interviewer-message {
  background-color: #fff;
  border-radius: 0px 12px 12px 12px;
}

.message-wrapper .respondent-message {
  background-color: #d3e6fd;
  border-radius: 12px 0px 12px 12px;
}

.message.message :global(.MuiCardContent-root) {
  padding: 1.6rem;
}

.interviewer-message-avatar {
  margin-right: 12px;
  height: 40px;
  width: 40px;
  background-image: linear-gradient(to right bottom, rgb(94, 252, 232), rgb(115, 110, 254));
  border-radius: 40px;
  box-shadow: rgb(255, 255, 255) 0 0 0 0 inset, rgba(0, 0, 0, 0.08) 0 0 0 0.75px inset, rgba(16, 24, 40, 0.06) 0 1px 2px 0, rgba(16, 24, 40, 0.1) 0 1px 3px 0;
  display: grid;
  place-items: center;
  position: relative;
}


.interviewer-message-avatar svg {
  color: white;
  width: 16px;
  height: 16px;
}

.input .send-button {
  background: transparent;
  color: rgb(4, 110, 230);
  padding: 8px 12px;
  -webkit-appearance: button;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 6px;
  white-space: nowrap;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  border: none;
}

.input .send-button svg {
  width: 20px;
  height: 20px;
}

.input .send-button:hover {
  color: rgb(3, 86, 180);
  background-color: rgb(240, 247, 255);
}

.input .send-button:disabled {
  color: rgb(152, 162, 179);
  pointer-events: none;
}

@media (max-width: 768px) {
  .chat {
    height: calc(100% - var(--info-panel-height-mobile));
  }

  .chat-dialog-wrapper {
    box-shadow: 0px 0px 0px 1px #e6e6e6;
    padding: 0 2.4rem;
  }

  .footer {
    background-color: var(--chat-background-color);
    padding: initial;
    border-top: none;
  }

  .user-input-wrapper {
    min-height: 100%;
    padding: 1.5rem 2.4rem;
    border-top: 1px solid #e6e6e6;
  }
}
