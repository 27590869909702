@import "reset-css";
@import "./variables.css";

html {
  height: 100%;
  font-size: 62.5%;
}
body {
  font-size: 1.6rem;
  height: 100%;
  margin: 0;
  font-family: "Proxima Nova", "Segoe UI", "Roboto", "Ubuntu", "Droid Sans",
    "Helvetica Neue", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  line-height: 2rem;
  color: #333;
  font-size: 1.4rem;
}

#root .MuiButton-sizeSmall {
  font-size: 1.4rem;
}

#root .MuiButton-sizeMedium {
  font-size: 1.6rem;
}

#root .MuiButton-sizeLarge {
  font-size: 1.8rem;
}

#root .MuiSvgIcon-fontSizeSmall {
  font-size: 2rem;
}

#root .MuiSvgIcon-fontSizeMedium {
  font-size: 2.4rem;
}
