.typing-progress {
  color: #999;
  font-style: italic;
}

.typing-progress span {
  color: #777;
  animation: typing-dots 1.2s infinite;
}

.typing-progress span:nth-child(1) {
  animation-delay: 1.2s;
}

.typing-progress span:nth-child(2) {
  animation-delay: 0.8s;
}

.typing-progress span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing-dots {
  0% {
    opacity: 0.75;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.25;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
