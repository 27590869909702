:root {
  --info-panel-width-desktop: 15%;
  --info-panel-width-mobile: initial;
  --info-panel-height-mobile: 8.4rem;
  --footer-height: 9rem;
  --border-radius: 2rem 2rem 0 0;
  --font-weight-medium: 500;
  --chat-background-color: #F6F9FF;
  --box-border: 1px solid #ddd;
}
