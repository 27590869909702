.chat-app {
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

@media (max-width: 768px) {
  .chat-app {
    flex-direction: column;
  }
}
