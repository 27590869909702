.info-panel {
  display: flex;
  padding: 2.4rem 3rem;
  flex-direction: column;
  width: var(--info-panel-width-desktop);
  border-right: var(--box-border);
}

.interviewer-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.interviewer-info :global(.MuiBadge-root .MuiBadge-badge) {
  background-color: #eee;
  color: var(--mui-palette-secondary-main);
  box-shadow: 0 0 0 2px #fff;
}

.interviewer-info .active :global(.MuiBadge-badge) {
  background-color: var(--mui-palette-secondary-main);
}

.active :global(.MuiBadge-badge) {
  background-color: var(--mui-palette-secondary-main);
}

.interviewer-info :global(.MuiBadge-root .MuiBadge-badge)::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: none;
  border: 1px solid #ccc;
  content: "";
  box-sizing: border-box;
}

.interviewer-info .active :global(.MuiBadge-badge)::after {
  animation: ripple 1.2s infinite ease-in-out;
  border: 1px solid currentColor;
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }
  100% {
    transform: scale(2.8);
    opacity: 0;
  }
}

.interviewer-info-description {
  font-weight: 500;
  font-size: 1.6rem;
}

.avatar {
  width: 4rem;
  height: 4rem;
}

@media (max-width: 768px) {
  .info-panel {
    padding: 2.4rem;
    flex-direction: row;
    width: var(--info-panel-width-mobile);
    border: none;
  }
  .interviewer-info :global(.MuiBadge-root) {
    transform: scale(1);
  }
}

.interviewer-message-avatar {
  height: 30px;
  width: 30px;
  background-color: #3a8fee;
  border-radius: 50%;
  display: grid;
  place-items: center;
  position: relative;
}

.interviewer-message-avatar p {
  color: white;
  font-weight: 500;
}
