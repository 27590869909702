.wrapper {
  background-color: white;
  padding: 6rem 9rem;
}

.wrapper ul {
  list-style-type: initial;
  margin-inline: 2rem;
  padding: 1rem;
}

.title.title {
  font-weight: var(--font-weight-medium);
  font-size: 1.8rem;
}

.begin-button-wrapper {
  width: 100%;
  max-width: 80%;
  padding-top: 1.2rem;
}

.terms {
  width: 100%;
  padding: 1rem 0;
}

@media (max-width: 768px) {
  .begin-button {
    max-width: 100%;
  }
  .wrapper {
    padding: 3rem;
  }
}

.fontWeightMedium.fontWeightMedium {
  font-size: 1.6rem;
  font-weight: var(--font-weight-medium);
}
